<template>
	<v-card flat color="transparent" :loading="loadingParent" max-width="800" class="mx-auto mt-2 mb-5 pb-2" rounded="xl">
		<v-card-title>
			<span>{{ $t('nav.newsfeed') }}</span>
			<v-spacer />
			<FeedNewNotice :event="event" />
		</v-card-title>
		<v-card-text>
			<FeedNotices :notices="notices" :loading-parent="loading || loadingParent" />
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'EventFeedNotices',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	components: {
		FeedNewNotice: () => import('@/components/feed/FeedNewNotice.vue'),
		FeedNotices: () => import('@/components/feed/FeedNotices.vue')
	},
	computed: {
		...mapGetters({
			event: 'events/event',
			notices: 'feed/notices'
		})
	},
	watch: {
		event() {
			this.loading = true
			this.fetchNoticesEvent({ eventID: this.event.id }).then(() => {
				this.loading = false
			})
		}
	},
	created() {
		this.loading = true
		this.fetchNoticesEvent({ eventID: this.event.id }).then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('feed', ['fetchNoticesEvent'])
	}
}
</script>
